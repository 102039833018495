import querystring from 'querystring'

// let query = querystring.stringify({
//     appid: 'wx8024788461d03897',
//     redirect_uri: encodeURIComponent('https://lm.ll1024.com/#/about'),
//     response_type: 'code',
//     scope: 'snsapi_userinfo',
//     state: 12
// }, null, null, {
//     encodeURIComponent: false
// })

let url = window.location.protocol + '//' + window.location.host
let query = 'appid=wx3bddd0511cdc2388&redirect_uri='+encodeURIComponent(url+'/#/auth')+
    '&response_type=code&scope=snsapi_userinfo&state='

export default {
    getCode: function() {
        return `https://open.weixin.qq.com/connect/oauth2/authorize?${query}#wechat_redirect`
    },
    getUserInfo: function(code) {
        return `https://api.weixin.qq.com/sns/oauth2/access_token?appid=wx8024788461d03897&secret=10ebd46f830e3a6212fa6c0db3ba9ce7&code=${code}&grant_type=authorization_code`
    }
}